import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import Seo from '../components/seo';

const IndexPage = () => {
    return (
        <Layout>

            <Seo title="Strona Główna"/>

            <section className='main-intro'>

                <StaticImage 
                    src='../images/landing_main.jpg' 
                    placeholder="blurred"
                    loading="eager"
                    alt="instruktorka trenująca pilates w studio" 
                    className='main-intro_picture'
                    transformOptions={{ fit: "cover" }}
                />
                <h2 className='main-intro_statement'>Jesteś architektem swojego ciała</h2>

                <div className='main-link'>
                    <Link to='/zapisy' className='main-link_link'>ZAPISZ SIĘ</Link>
                </div>
             </section>

            <section className='main-offer'>

                <section className='main-offer_card'>
                    <div className='main-offer_card_picture-container'>
                        <StaticImage 
                            src='../images/main_first.jpg' 
                            alt="Maszyna do ćwiczeń Pilates" 
                            className='main-offer_card_picture'
                        />
                    </div>
                    <div className='main-offer_card_text-container'>
                        <h1>Zajęcia indywidualne</h1>
                        <p>Zajęcia indywidualne Pilates prowadzone na podstawie rozbudowanej diagnostyki realizowane na maszynach Reformer with Tower, Wunda Chair, Ladder Barrel czy Spine Corrector.</p>
                        <div className='main-offer_card_link-container'>
                            <Link to='/oferta-indywidualna' className='main-offer_card_link'>SZCZEGÓŁY</Link>
                        </div>
                    </div>
                </section>

                <section className='main-offer_card'>

                    <div className='main-offer_card_picture-container'>
                        <StaticImage 
                            src='../images/main_second.jpg' 
                            alt="Maszyny typu Spine Corrector" 
                            className='main-offer_card_picture'
                        />

                    </div>

                    <div className='main-offer_card_text-container'>
                        <h1>Duety</h1>

                        <p>Zajęcia w prywatnych duetach na maszynach pilatesowych oraz na macie z małym sprzętem.</p>
                        <div className='main-offer_card_link-container'>
                            <Link to='/oferta-duety' className='main-offer_card_link'>SZCZEGÓŁY</Link>
                        </div>
                    </div>
                </section>

                <section className='main-offer_card'>
                    <div className='main-offer_card_picture-container'>
                        <StaticImage 
                            src='../images/main_third.jpg' 
                            alt="symetryczna architektura" 
                            className='main-offer_card_picture'
                        />
                    </div>
                    <div className='main-offer_card_text-container'>
                        <h1>Oferta dla firm</h1>
                        <p>Programy edukacyjne oraz treningowe dla pracowników firm w formie regularnej lub podczas pojedynczych eventów.</p>
                        <div className='main-offer_card_link-container'>
                            <Link to='/oferta-dla-firm' className='main-offer_card_link'>SZCZEGÓŁY</Link>
                        </div>
                    </div>
                </section>

                <section className='main-offer_card'>
                    <div className='main-offer_card_picture-container'>
                        <StaticImage 
                            src='../images/main_fourth.jpg' 
                            alt="praktyka pilates na sali treningowej" 
                            className='main-offer_card_picture'
                        />
                    </div>

                    <div className='main-offer_card_text-container'>
                        <h1>Oferta dla instruktorów</h1>
                        <p>Oferta programów rozwojowych i szkoleń dla instruktorów różnych metod pracy z ciałem oraz możliwość współpracy partnerskiej w ramach wymiany usług i korzystania z infrastruktury.</p>
                        <div className='main-offer_card_link-container'>
                            <Link to='/warsztaty-dla-instruktorow' className='main-offer_card_link'>SZCZEGÓŁY</Link>
                        </div>
                    </div>
                </section>

            </section>
            
            <section className='main-about'>
                <div className='main-about_picture-container'>
                    <StaticImage 
                        src='../images/main_who.jpg' 
                        alt="jasna sala do treningu pilates" 
                        className='main-about_picture'
                    />
                </div>
                <div className='main-about_text-container'>
                    <h2>Kim jesteśmy?</h2>
                    <h3>Jesteśmy profesjonalnym Studiem Pilatesu terapeutycznego. Pracujemy w nurcie współczesnym, dopasowując ćwiczenia i ich modyfikacje do możliwości, potrzeb i celów Klienta.<br/><br/>
                    Nasze Studio wyposażone jest w maszyny: Reformer with Tower, Cadillac, Wunda Chair, Combo Chair, Ladder Barrel, Spine Corrector oraz Clinical Reformer, który jest jedynym sprzętem Pilates dostosowanym do pracy z dziećmi i młodzieżą.<br/><br/>
                    Prowadzimy zajęcia w różnych językach, stacjonarne oraz on-line.
                    </h3>
                    <div className='main-about_link-group'>
                        <div className='main-about_link-container'>
                            <Link to='/o-nas' className='main-about_link'>NASZ PILATES</Link>
                        </div>
                        <div className='main-about_link-container'>
                            <Link to='/zespol' className='main-about_link'>NASZ ZESPÓŁ</Link>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default IndexPage;